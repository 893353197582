<template>
  <div>
    <v-data-table
      class="data__table wardens-table"
      ref="table"
      :headers="headers"
      :items="wardensList"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.name="{ item }">
        {{ item.name }}
        <v-chip
          v-if="item.warden_type === 'merchant'"
          class="px-2 ml-2 wardens-table-chip"
          color="#333333"
          small
          outlined
        >
          Primary
        </v-chip>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action d-flex justify-end">
          <div>
            <v-hover v-slot="{ hover }">
              <v-icon
                :color="hover ? 'primary' : '#B9B9B9'"
                @click="showPopupForWarden(item)"
              >
                mdi-chevron-right
              </v-icon>
            </v-hover>
          </div>
        </div>
      </template>
    </v-data-table>
    <links-for-wardening-popup
      :showPopup="showWardeningPopup"
      :warden="selectedWarden"
      @close="closeWardeningPopup"
    />
  </div>
</template>

<script>
import LinksForWardeningPopup from '@/components/Locations/AddVisitorParking/Subtabs/LinksForWardeningPopup.vue'

export default {
  name: 'SubtabWarden',
  components: { LinksForWardeningPopup },
  props: {
    wardensList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      locationHeaders: [
        { text: this.$t('warden_name'), value: 'name' },
        { text: '', value: 'id', sortabl: false }
      ],
      merchantHeaders: [
        { text: this.$t('warden_name'), value: 'name' },
        { text: this.$t('locations'), value: 'locations' },
        { text: '', value: 'id', sortabl: false }
      ],
      mobileBreakpoint: 767,
      showWardeningPopup: false,
      selectedWarden: {}
    }
  },
  computed: {
    headers() {
      return this.$route.name === 'Merchant'
        ? this.merchantHeaders
        : this.locationHeaders
    }
  },
  methods: {
    showPopupForWarden(warden) {
      this.selectedWarden = warden
      this.showWardeningPopup = true
    },
    closeWardeningPopup() {
      this.selectedWarden = {}
      this.showWardeningPopup = false
    }
  }
}
</script>

<style lang="scss" scoped>
.wardens-table {
  &-chip {
    height: 12px;
    font-size: 10px;
  }
  .mobile-row__action {
    .theme--light:focus:before {
      opacity: 1 !important;
    }
  }
}
</style>
