<template>
  <div class="hubs-table">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="hubsList"
      fixed-header
      hide-default-footer
      disable-pagination
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.hub_locations="{ item }">
        <v-tooltip bottom max-width="500" :key="item.id">
          <template v-slot:activator="{ on }">
            <div class="hubs-table-locations" v-on="on">
              {{ item.hub_locations.join(', ') }}
            </div>
          </template>
          {{ item.hub_locations.join(', ') }}
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'SubtabHubs',
  props: {
    hubsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      locationHeaders: [
        { text: this.$t('hub_name'), value: 'hub_name' },
        { text: this.$t('hub_id'), value: 'hub_location_code' }
      ],
      merchantHeaders: [
        { text: this.$t('hub_name'), value: 'hub_name' },
        { text: this.$t('locations'), value: 'hub_locations' }
      ],
      mobileBreakpoint: 767
    }
  },
  computed: {
    headers() {
      return this.$route.name === 'Merchant'
        ? this.merchantHeaders
        : this.locationHeaders
    }
  }
}
</script>

<style lang="scss">
.hubs-table {
  table {
    table-layout: fixed;
  }
  &-locations {
    max-width: 100%;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
