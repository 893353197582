<template>
  <div class="tabs-with-paddings">
    <v-tabs class="tabs mt-4" v-model="currentItem" left slider-color="white">
      <v-tab class="smaller-tab pl-0" v-show="hubsList.length" key="hubs">
        HUB
      </v-tab>
      <v-tab
        v-show="wardensList.length"
        :class="{
          'smaller-tab': true,
          'pl-0': !hubsList.length && wardensList.length
        }"
        key="wardens"
      >
        Warden
      </v-tab>
      <v-tab v-show="false" class="smaller-tab"> Camera </v-tab>
    </v-tabs>

    <v-tabs-items class="tab-integrations-items" v-model="currentItem">
      <v-tab-item class="tab-item" key="hubs">
        <v-divider class="mb-4" />
        <subtab-hubs :hubsList="hubsList" />
      </v-tab-item>
      <v-tab-item class="tab-item" key="wardens">
        <v-divider class="mb-4" />
        <subtab-warden :wardensList="wardensList" />
      </v-tab-item>
      <v-tab-item v-show="false" class="tab-item">
        <v-divider class="mb-4" />
        Camera
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import SubtabHubs from '@/components/Locations/AddVisitorParking/Subtabs/SubtabHubs.vue'
import SubtabWarden from '@/components/Locations/AddVisitorParking/Subtabs/SubtabWarden.vue'

export default {
  name: 'TabIntegrations',
  components: { SubtabHubs, SubtabWarden },
  props: {
    hubsList: {
      type: Array,
      default: () => []
    },
    wardensList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    currentItem: 0
  }),
  watch: {
    hubsList(list) {
      this.currentItem = list.length ? 0 : 1
    }
  },
  mounted() {
    this.currentItem = this.hubsList.length ? 0 : 1
  }
}
</script>

<style lang="scss" scoped>
@import '~@/style/common';
@import '~@/style/variables';

.tabs {
  margin-left: 0;
  margin-top: 0;
  max-height: 40px;
}
</style>
